<template>
  <notifications position="top center" width="320" closeOnClick>
    <template #body="{ item }">
      <div
        class="custom-notification"
        :class="`custom-notification--${item.type}`"
      >
        <button
          v-if="item.data.click"
          @click="item.data.click"
          class="custom-notification__close"
        ></button>
        <div class="custom-notification__icon"></div>
        <div v-if="item.title" class="custom-notification__title">
          {{ item.title }}
        </div>
        <div class="custom-notification__text" v-html="item.text" />
        <button
          v-if="item.data.click"
          class="custom-notification__cta"
          @click="item.data.click"
        >
          OK
        </button>
      </div>
    </template>
  </notifications>
  <Nav v-if="!route.meta.hideNavbar" :currentUser="currentUser" />
  <main>
    <LoadingSpinner v-if="loading" />
    <router-view v-else />
  </main>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import type { Ref } from 'vue'
import Nav from '@/components/Nav/index.vue'
import 'normalize.css'
import '@fontsource/source-sans-pro'
import '@fontsource/source-code-pro'
import 'material-icons/iconfont/filled.css'
import LoadingSpinner from './components/LoadingSpinner/index.vue'
import { usersApi } from '@/api/usersApi'
import { User } from '@/api/authApi/types'
import authStore from '@/store/auth'
import { useRoute, useRouter } from 'vue-router'

const currentUser: Ref<User | null> = ref(null)
const loading = ref<boolean>(true)
const route = useRoute()
const router = useRouter()
const { setCurrentUserUuid } = authStore

watch(() => route.fullPath, refreshUserData)

async function logout() {
  await authStore.logoutUser()
  await router.push('/login')
}

async function refreshUserData() {
  const user = await usersApi.get()
  if (user) {
    currentUser.value = user
    setCurrentUserUuid(user.uuid)
  } else {
    currentUser.value = null
    if (!router.currentRoute.value.meta.skipAuth) await logout()
  }
  loading.value = false
}

onMounted(refreshUserData)
</script>

<style lang="scss">
@import './assets/css/universal.scss';
@import './assets/css/controls.scss';
@import './assets/css/_mixins.scss';
@import './assets/css/_variables.scss';
@import './assets/css/custom_notification.scss';
</style>
