import { createI18n } from 'vue-i18n'
import deTranslations from '@/locales/de.json'
import enTranslations from '@/locales/en.json'
import plTranslations from '@/locales/pl.json'
import uaTranslations from '@/locales/ua.json'
import { LOCALES } from './config/locales'
import userStore from '@/store/user'
const { currentUserLocale } = userStore

export const i18n = createI18n({
  legacy: false,
  locale: currentUserLocale.value || LOCALES.EN,
  fallbackLocale: LOCALES.EN,
  globalInjection: true,
  messages: {
    de: deTranslations,
    en: enTranslations,
    pl: plTranslations,
    ua: uaTranslations,
  },
})
export const t = i18n.global.t

export const availableLocales: Array<string> = i18n.global.availableLocales

export const scopedTranslation = (
  firstLevelKey: keyof typeof enTranslations | keyof typeof deTranslations,
): Record<string, (secondLevelKey: string) => string> => {
  return {
    sT: (secondLevelKey: string) => {
      return i18n.global.t(`${firstLevelKey}.${secondLevelKey}`)
    },
  }
}

// We need to use "unknown" type for the argument and typecast the value "as number"
// inside the method instead of typecasting when passing the argument.
// We call this method in Vue templates and while TypeScript in templates is supported
// using TypeScript syntax in templates seems to break vue-jest component tests.
// See related issue I found here:
// https://github.com/quasarframework/quasar-testing/issues/204#issuecomment-1025745340
// https://vuejs.org/guide/typescript/overview.html#typescript-in-templates
export const decimalFormat = (value: unknown): string => {
  const locales = currentUserLocale.value || LOCALES.EN
  const formatter = new Intl.NumberFormat(locales, {
    style: 'decimal',
    maximumFractionDigits: 2,
  })
  return formatter.format(value as number)
}
