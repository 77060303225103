import authApi from '@/api/authApi'
import { usersApi } from '@/api/usersApi'
import { ref } from 'vue'
import { getStringFromStore, setLocaleStorage } from '@/store'
import userStore from '@/store/user'
import { LoginParams } from '@/api/authApi/types'
import { LOCALES } from '@/config/locales'
import { adLogout } from '@/util/active_directory_helper'

export interface AuthEntries {
  selectedLocale: string
  currentUserUuid: string
}

const authStore = {
  selectedLocale: ref<AuthEntries['selectedLocale'] | null>(
    getStringFromStore('selectedLocale'),
  ),
  currentUserUuid: ref<AuthEntries['currentUserUuid'] | null>(
    getStringFromStore('currentUserUuid'),
  ),
}

const setSelectedLocale = (newLocale: LOCALES | null): void => {
  setLocaleStorage('selectedLocale', newLocale)
  authStore.selectedLocale.value = newLocale
}

const setCurrentUserUuid = (newCurrentUserUuid: string | null): void => {
  setLocaleStorage('currentUserUuid', newCurrentUserUuid)
  authStore.currentUserUuid.value = newCurrentUserUuid
}

// getters

/**
 * Send login api request. Stores user in store if successful and
 * displays notification accordingly
 */
const loginUser = async (params: LoginParams): Promise<void> => {
  const user = await authApi.login({
    email: params.email,
    password: params.password,
  })
  if (user) {
    setCurrentUserUuid(user.uuid)

    const locale = userStore.currentUserLocale.value
    if (locale && locale !== user.language) {
      await usersApi.updateUserLanguage(locale)
    }
  } else {
    await logoutUser()
  }
}

const logoutUser = async (): Promise<void> => {
  setCurrentUserUuid(null)
  window.sessionStorage.clear()
  await adLogout()
  await authApi.logout()
}

/**
 * Clear all store values
 */
const clear = (): void => {
  setSelectedLocale(null)
  setCurrentUserUuid(null)
}

export default {
  ...authStore,
  setSelectedLocale,
  setCurrentUserUuid,
  loginUser,
  logoutUser,
  clear,
}
