import { ref } from 'vue'
import { getStringFromStore, setLocaleStorage } from '@/store'

export interface UserEntries {
  currentCompanyUuid: string
  currentUserLocale: string
  currentVehicleUuid: string
}

const userStore = {
  currentCompanyUuid: ref<UserEntries['currentCompanyUuid'] | null>(
    getStringFromStore('currentCompanyUuid'),
  ),
  currentUserLocale: ref<UserEntries['currentUserLocale'] | null>(
    getStringFromStore('currentUserLocale'),
  ),
  currentVehicleUuid: ref<UserEntries['currentVehicleUuid'] | null>(
    getStringFromStore('currentVehicleUuid'),
  ),
}

const setCurrentCompanyUuid = (newCompanyUuid: string | null): void => {
  setLocaleStorage('currentCompanyUuid', newCompanyUuid)
  userStore.currentCompanyUuid.value = newCompanyUuid
}

const setCurrentUserLocale = (currentUserLocale: string | null): void => {
  setLocaleStorage('currentUserLocale', currentUserLocale)
  userStore.currentUserLocale.value = currentUserLocale
}

const setCurrentVehicleUuid = (newVehicleUuid: string | null): void => {
  setLocaleStorage('currentVehicleUuid', newVehicleUuid)
  userStore.currentVehicleUuid.value = newVehicleUuid
}

const clear = (): void => {
  setCurrentCompanyUuid(null)
  setCurrentUserLocale(null)
  setCurrentVehicleUuid(null)
}

export default {
  ...userStore,
  setCurrentCompanyUuid,
  setCurrentUserLocale,
  setCurrentVehicleUuid,
  clear,
}
