import { AuthEntries } from './auth'
import { OrderEntries } from './order'
import { UserEntries } from './user'
import { WeighingEntries } from './weighing'

interface StoreEntries
  extends AuthEntries,
    UserEntries,
    OrderEntries,
    WeighingEntries {}
type StoreEntryKey = keyof StoreEntries

const KEY_PREFIX = `${process.env.NODE_ENV}-binity-driver-apk`

const prefixKey = (key: StoreEntryKey) => {
  return `${KEY_PREFIX}-${key}`
}

export const getStringFromStore = <
  T extends StoreEntries,
  K extends StoreEntryKey,
>(
  key: K,
): T[K] | null => {
  return getLocaleStorage(key)
}

export const getStringArrayFromStore = (key: StoreEntryKey): string[] => {
  const result = getLocaleStorage(key)
  if (result) {
    return result as string[]
  } else {
    // init as empty when it's not there
    setLocaleStorage(key, [])
    return []
  }
}

export const addUniqueEntryToStore = (
  key: StoreEntryKey,
  newEntry: string,
): string[] => {
  const presentEntries = getStringArrayFromStore(key)
  const updatedEntries = Array.from(new Set([...presentEntries, newEntry]))
  setLocaleStorage(key, updatedEntries)
  return updatedEntries
}

export const removeEntryFromStore = (
  key: StoreEntryKey,
  removeEntry: string,
): string[] => {
  const presentEntries = getStringArrayFromStore(key)
  const updatedEntries = presentEntries.filter(
    (entry: string) => entry !== removeEntry,
  )
  setLocaleStorage(key, updatedEntries)
  return updatedEntries
}

const getLocaleStorage = <T extends StoreEntries, K extends StoreEntryKey>(
  key: K,
): T[K] | null => {
  const prefixedKey = prefixKey(key)
  const item = localStorage.getItem(prefixedKey)
  if (item) {
    try {
      return JSON.parse(item)
    } catch (SyntaxError) {
      setLocaleStorage(key, null)
      return null
    }
  } else {
    return null
  }
}

export const setLocaleStorage = <
  T extends StoreEntries,
  K extends StoreEntryKey,
>(
  key: K,
  value: T[K] | null,
): void => {
  const prefixedKey = prefixKey(key)
  localStorage.setItem(prefixedKey, JSON.stringify(value))
}
