import authRoutes from './routes'
import axios from 'axios'
import { post, remove } from '../base'
import {
  LoginParams,
  LoginResponseData,
  LogoutResponseData,
  User,
} from './types'
import { isSuccessful } from '../utils'

axios.defaults.withCredentials = true

export default {
  login: async (loginParams: LoginParams): Promise<User | null> => {
    const response = await post<LoginResponseData>({
      path: authRoutes.LOGIN,
      queryParams: {
        email: loginParams.email,
        password: loginParams.password,
      },
    })
    if (isSuccessful(response)) {
      return response.data.user
    } else {
      return null
    }
  },
  logout: async (): Promise<LogoutResponseData> => {
    const response = await remove<LogoutResponseData>({
      path: authRoutes.LOGOUT,
    })
    return response
  },
}
