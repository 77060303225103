<template>
  <div class="loading-wrap">
    <div class="loading-spinner" :class="props.animationVariant" />
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    animationVariant?: string
  }>(),
  {
    animationVariant: 'loading-spinner--blue',
  },
)
</script>

<style scoped lang="scss">
@import url('./LoadingSpinner.scss');
</style>
