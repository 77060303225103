<template>
  <header v-if="props.currentUser" class="nav__header">
    <div class="nav__group">
      <button
        class="back_button"
        v-if="route.name === 'Action'"
        @click="router.push({ name: 'AssignedOrders' })"
      >
        {{ t(`nav.${route.params.name}`) }}
      </button>
      <router-link
        v-else-if="hasChosenVehicle"
        to="/"
        :title="`${t('assignedOrders.title')} ${currentVehicle?.name}`"
      >
        {{ t('assignedOrders.title') }} {{ currentVehicle?.name }}
      </router-link>
    </div>

    <div class="nav__group">
      <div class="menu-toggle">
        <input
          type="checkbox"
          :checked="isMenuOpen"
          @click="isMenuOpen = !isMenuOpen"
        />
        <span class="lines"></span>
        <div class="fold-out-menu">
          <router-link
            class="fold-out-menu__user"
            to="/info"
            :title="t('info.loginDetails')"
            @click="isMenuOpen = false"
            ><span
              >{{ props.currentUser?.first_name }}
              {{ props.currentUser?.last_name }}</span
            >
            {{ props.currentUser?.email }}
          </router-link>

          <ul class="fold-out-menu__links">
            <li>
              <router-link
                to="/vehicles/selection"
                :title="t('vehicleSelection.changeVehicle')"
                @click="isMenuOpen = false"
              >
                {{ t('vehicleSelection.changeVehicle') }}
              </router-link>
            </li>
            <li>
              <router-link
                to="/info"
                :title="t('info.changeLocale')"
                @click="isMenuOpen = false"
              >
                {{ t('info.changeLocale') }}
              </router-link>
            </li>
            <li>
              <button :title="t('meta.logout')" @click="logoutUser">
                {{ t('meta.logout') }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import type { Ref } from 'vue'
import { User } from '@/api/authApi/types'
import { vehiclesApi } from '@/api/vehiclesApi'
import { Vehicle } from '@/api/vehiclesApi/types'
import { t } from '@/i18n'
import { useRouter, useRoute } from 'vue-router'
import authStore from '@/store/auth'
import userStore from '@/store/user'

const router = useRouter()
const route = useRoute()
const props = defineProps<{ currentUser: User | null }>()

const { currentVehicleUuid, setCurrentVehicleUuid } = userStore
const currentVehicle: Ref<Vehicle | null> = ref(null)
const isMenuOpen = ref<boolean>(false)

const hasChosenVehicle = computed(() => !!currentVehicleUuid.value)

const logoutUser = async () => {
  isMenuOpen.value = false
  await authStore.logoutUser()
  await router.push('/login')
}

watch(
  () => currentVehicleUuid.value,
  async vehicleUuid => {
    if (vehicleUuid) {
      currentVehicle.value = await vehiclesApi.get(vehicleUuid)
      if (currentVehicle.value) setCurrentVehicleUuid(currentVehicle.value.uuid)
    }
  },
)

onMounted(async () => {
  if (currentVehicleUuid.value) {
    currentVehicle.value = await vehiclesApi.get(currentVehicleUuid.value)
    if (currentVehicle.value) setCurrentVehicleUuid(currentVehicle.value.uuid)
  }
})
</script>

<style scoped lang="scss">
@import './Nav.scss';
</style>
