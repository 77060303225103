import axios from 'axios'
import { notify } from '@kyvg/vue3-notification'
import { notificationTypes } from '@/constants/notifications'
import { ErrorResponse, Request, SuccessfulResponse } from './types'
import { ref } from 'vue'
import { t } from '@/i18n'
import router from '@/router'
import { getAdTokenRedirect } from '@/util/active_directory_helper'

axios.defaults.withCredentials = true

const baseUrl = process.env.VUE_APP_BASE_API
export const requestPending = ref(false)
const lastGenericErrorTimestamp = ref(0)

const getBaseHeaders = async (): Promise<Record<string, string | never>> => {
  const token = await getAdTokenRedirect()
  if (!token) return {}
  return { Authorization: `Bearer ${token}` }
}

export async function get<ResultType>({
  path,
  queryParams,
}: Request): Promise<SuccessfulResponse<ResultType> | ErrorResponse> {
  let response
  try {
    requestPending.value = true
    response = await axios.get(`${baseUrl}/${path}`, {
      headers: await getBaseHeaders(),
      ...(!!queryParams && { params: { ...queryParams } }),
    })
    return {
      success: true,
      data: response.data,
    }
  } catch (error) {
    return handleError(error)
  } finally {
    requestPending.value = false
  }
}

export async function patch<ResultType>({
  path,
  queryParams,
}: Request): Promise<SuccessfulResponse<ResultType> | ErrorResponse> {
  let response
  try {
    requestPending.value = true
    let params = {
      ...(!!queryParams && { ...queryParams }),
    }
    if (queryParams instanceof FormData) {
      params = queryParams
    }
    response = await axios.patch(`${baseUrl}/${path}`, params, {
      headers: await getBaseHeaders(),
    })
    return { success: true, data: response.data }
  } catch (error) {
    return handleError(error)
  } finally {
    requestPending.value = false
  }
}

export async function post<ResultType>({
  path,
  queryParams,
}: Request): Promise<SuccessfulResponse<ResultType> | ErrorResponse> {
  let response
  try {
    requestPending.value = true
    let params = {
      ...(!!queryParams && { ...queryParams }),
    }
    if (queryParams instanceof FormData) {
      params = queryParams
    }
    response = await axios.post(`${baseUrl}/${path}`, params, {
      headers: await getBaseHeaders(),
    })
    return {
      success: true,
      data: response.data,
    } as SuccessfulResponse<ResultType>
  } catch (error) {
    return handleError(error)
  } finally {
    requestPending.value = false
  }
}

export async function remove<ResultType>({
  path,
  queryParams,
}: Request): Promise<SuccessfulResponse<ResultType> | ErrorResponse> {
  let response
  try {
    requestPending.value = true
    response = await axios.delete(`${baseUrl}/${path}`, {
      headers: await getBaseHeaders(),
      ...(!!queryParams && { params: { ...queryParams } }),
    })

    return {
      success: true,
      data: response.data,
    }
  } catch (error) {
    return handleError(error)
  } finally {
    requestPending.value = false
  }
}

function showErrorNotification(errors: string[]) {
  if (errors?.length > 0) {
    const id = Date.now()
    notify({
      id: id,
      type: notificationTypes.ERROR,
      title: t('meta.errorOoops'),
      text: errors.join('<br>'),
      duration: -1,
      data: {
        click: () => {
          notify.close(id)
        },
      },
    })
  } else if (lastGenericErrorTimestamp.value + 1000 < new Date().getTime()) {
    // Don't show a wall of generic errors, throttled to once per second
    lastGenericErrorTimestamp.value = new Date().getTime()
    // Explicitly tell the user that something is wrong with the API response (malformed, down, ...)
    notify({
      id: lastGenericErrorTimestamp as unknown as number,
      type: notificationTypes.ERROR,
      title: t('meta.errorOoops'),
      text: t('meta.genericApiError'),
      data: {
        click: () => {
          notify.close(lastGenericErrorTimestamp)
        },
      },
    })
  }
}

function handleError(error: unknown): ErrorResponse {
  let errors: string[] = []
  let errorStatus = undefined
  if (axios.isAxiosError(error)) {
    errorStatus = error.response?.status
    errors = error.response?.data.errors
      ? error.response?.data.errors
      : [error.response?.data]
    if (error.response?.config.method === 'get') {
      if (
        error.response?.status !== 401 ||
        !router.currentRoute.value.meta.skipAuth
      ) {
        // If we can skipAuth on the route and get a 401 that is fine, we are probably
        // just loading the user to display the nav, but the site should be usable without.
        // So we should not send notifications etc.
        showErrorNotification(errors)
      }
    } else {
      showErrorNotification(errors)
    }
  } else {
    errors = [t('meta.genericApiError')]
  }

  return {
    success: false,
    errors: errors,
    status: errorStatus,
  }
}
