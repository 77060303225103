import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import Notifications from '@kyvg/vue3-notification'
import QrReader from 'vue3-qr-reader'
import { i18n } from './i18n'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

const app = createApp(App)

app.component('v-select', vSelect)

app.use(QrReader)
/* By registering the qr code scanner globally, multiple custom components
  are registered. For example qr-stream for the usage of our qr code
  scanner. By telling the compiler that we expect a custom element with
  a certain name, we ensure that we are aware of the existence of that
  particular component. */
app.config.compilerOptions.isCustomElement = tag => tag.includes('qr-stream')

const unsupportedSentryEnvironments = ['development', 'test']
if (!unsupportedSentryEnvironments.includes(process.env.NODE_ENV || '')) {
  Sentry.init({
    app,
    dsn: 'https://9c1dc5559bcf4e3aaca64d807772b15e@o1082260.ingest.sentry.io/4504197282922496',
    environment: process.env.NODE_ENV,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['driver.staging.binity.io', 'driver.binity.io', /^\//],
      }),
    ],
    tracesSampleRate: 0.2,
  })
}

app.use(router)
app.use(Notifications)
app.use(i18n)
app.mount('#app')
