import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import authStore from '@/store/auth'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'AssignedOrders',
    component: () => import('../views/AssignedOrders/index.vue'),
    meta: {
      skipAuth: false,
      hideNavbar: false,
    },
  },
  {
    path: '/action/:name',
    name: 'Action',
    component: () => import('../views/Action/index.vue'),
    props: true,
    meta: {
      skipAuth: false,
      hideNavbar: false,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login/index.vue'),
    meta: {
      skipAuth: true,
      hideNavbar: true,
    },
  },
  {
    path: '/vehicles/selection',
    name: 'VehicleSelection',
    component: () => import('../views/Vehicles/index.vue'),
    meta: {
      skipAuth: false,
      hideNavbar: false,
    },
  },
  {
    path: '/info',
    name: 'InfoDashboard',
    component: () => import('../views/InfoDashboard/index.vue'),
    meta: {
      skipAuth: false,
      hideNavbar: false,
    },
  },
  {
    path: '/404',
    component: () => import('../views/PageNotFound/index.vue'),
  },
  { path: '/:catchAll(.*)', redirect: '/404' },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async to => {
  // this route requires auth, check if logged in
  // and if not, redirect to login page.
  if (!to.meta.skipAuth && !authStore.currentUserUuid.value) {
    return {
      path: '/login',
    }
  }
})

export default router
