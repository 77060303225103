import { get } from '../base'
import { Vehicle } from './types'
import { GetResponseData, GetAllResponseData } from '@/api/vehiclesApi/types'
import { isSuccessful } from '../utils'

export const vehiclesApi = {
  get: async (vehicleUuid: string): Promise<Vehicle | null> => {
    const response = await get<GetResponseData>({
      path: `vehicles/${vehicleUuid}`,
    })
    if (isSuccessful(response)) {
      return response.data.vehicle
    } else {
      return null
    }
  },
  getAllForCompany: async (companyUuid: string): Promise<Vehicle[]> => {
    const response = await get<GetAllResponseData>({
      path: `vehicles`,
      queryParams: { company_uuid: companyUuid },
    })
    if (isSuccessful(response)) {
      return response.data.vehicles
    } else {
      return []
    }
  },
}
