import { User } from '../authApi/types'
import { get, patch } from '../base'
import { GetResponseData } from './types'
import userStore from '@/store/user'
import { isSuccessful } from '../utils'
const { setCurrentUserLocale } = userStore

export const usersApi = {
  get: async (): Promise<User | null> => {
    const response = await get<GetResponseData>({
      path: 'user',
    })
    if (isSuccessful(response)) {
      const user = response.data.user
      setCurrentUserLocale(user.language)
      return user
    } else {
      return null
    }
  },
  updateUserLanguage: async (language: string): Promise<User | null> => {
    const response = await patch<GetResponseData>({
      path: 'user',
      queryParams: { language: language },
    })
    if (isSuccessful(response)) {
      return response.data.user
    } else {
      return null
    }
  },
}
